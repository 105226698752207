// @flow
import { Fragment } from "react";
import styled, { css } from "styled-components";
import { media } from "@nested/brand";
import { SmartLink, ButtonLink } from "@nested/component-library";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { regularHeading } from "../../components/Typography/Headings";
import { mediumParagraph } from "../../components/Typography/Paragraphs";
import { whiteSliceAdjacentSliceRules } from "../AdjacentSliceRules/AdjacentSliceRules";

const backgroundStyle = css`
  padding: 40px 20px;
  background-color: white;
  ${whiteSliceAdjacentSliceRules}
`;

const wrapperStyle = css`
  max-width: 1140px;
  margin: 0px auto;
  ${media.desktop`
    text-align: center;
    align-items: stretch;
    display: flex;
  `}
`;

const columnStyle = css`
  text-align: center;
  margin: 0px auto;
  &:not(:first-of-type) {
    margin: 40px 0px auto;
  }
  ${media.tablet`
    text-align: left;
    display: flex;
    flex-direction: row;
  `};

  ${media.desktop`
    display: inline-flex;
    flex-direction: column;
    width: calc(100% / 3);
    text-align: center;
    align-items: stretch;
    && {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &:nth-of-type(2) {
      margin-right: 40px;
      margin-left: 40px;
    }
  `}
`;

const headingStyle = css`
  ${regularHeading}
  white-space: pre-wrap;
  margin-top: 30px;
  margin-bottom: 20px;
  ${media.tablet`
    margin-top: 0px;
    max-width: 450px;
  `}
  ${media.desktop`
    margin-top: 30px;
    flex-grow: 0;
  `}
`;

const paragraphStyle = css`
  ${mediumParagraph}
  margin-top: 20px;
  margin-bottom: 0px;
  ${media.tablet`
    max-width: 450px;
  `}
  ${media.desktop`
    flex-grow: 1;
    margin-top: 0px;
  `}
`;

const buttonWrapperStyle = css`
  margin-top: 30px;
  ${media.tablet`
    margin-top: 20px;
  `};
  ${media.desktop`
    flex-grow: 0;
  `}
`;

const imageStyle = css`
  width: 100px;
  ${media.desktop`
    width: 120px;
  `}
`;

const imageWrapperStyle = css`
  ${media.tablet`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% / 3 - 20px);
    margin-right: 40px;
  `};

  ${media.desktop`
    display: block;
    text-align: center;
    width: 100%;
    margin: 0px;
  `}
`;

const bottomButtonWrapper = css`
  text-align: center;
  margin-top: 30px;
  ${media.desktop`
    margin-top: 50px;
  `}
`;

const contentWrapperStyle = css`
  ${media.tablet`
    width: calc(100% / 3 * 2);
  `};

  ${media.desktop`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-left: 0px;
  `};
`;

const StyledSmartLink = styled(SmartLink)`
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
`;

const Column = ({ image, heading, paragraph, buttonLink, buttonLabel }) => {
  const LinkWrapper = buttonLink?.url ? StyledSmartLink : Fragment;
  return (
    <div css={columnStyle}>
      {image && image.url && (
        <div css={imageWrapperStyle}>
          <LinkWrapper to={buttonLink?.url}>
            <ResponsiveImage css={imageStyle} src={image.url} alt={image.alt} />
          </LinkWrapper>
        </div>
      )}
      <div css={contentWrapperStyle}>
        <h3 css={headingStyle}>
          <LinkWrapper to={buttonLink?.url}>{heading}</LinkWrapper>
        </h3>
        <p css={paragraphStyle}>{paragraph}</p>
        {buttonLink && buttonLabel && (
          <div css={buttonWrapperStyle}>
            <ButtonLink
              colour="sand"
              to={buttonLink.url}
              target={buttonLink?.target}
            >
              {buttonLabel}
            </ButtonLink>
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  columnOneImage?: PrismicImage,
  columnOneHeading?: string,
  columnOneParagraph?: string,
  columnOneButtonLink?: PrismicLink,
  columnOneButtonLabel?: string,
  columnTwoImage?: PrismicImage,
  columnTwoHeading?: string,
  columnTwoParagraph?: string,
  columnTwoButtonLink?: PrismicLink,
  columnTwoButtonLabel?: string,
  columnThreeImage?: PrismicImage,
  columnThreeHeading?: string,
  columnThreeParagraph?: string,
  columnThreeButtonLink?: PrismicLink,
  columnThreeButtonLabel?: string,
  bottomButtonLabel?: string,
  bottomButtonLink?: PrismicLink,
  bottomButtonStyle?: "standard" | "primary" | "accent",
  bottomButtonSize?: "medium" | "large",
};

export const ThreeColumnCta = ({
  columnOneImage,
  columnOneHeading,
  columnOneParagraph,
  columnOneButtonLink,
  columnOneButtonLabel,
  columnTwoImage,
  columnTwoHeading,
  columnTwoParagraph,
  columnTwoButtonLink,
  columnTwoButtonLabel,
  columnThreeImage,
  columnThreeHeading,
  columnThreeParagraph,
  columnThreeButtonLink,
  columnThreeButtonLabel,
  bottomButtonLabel,
  bottomButtonLink,
  bottomButtonStyle,
  bottomButtonSize,
}: Props) => (
  <div css={backgroundStyle} className="optional-top-padding">
    <div css={wrapperStyle}>
      <Column
        image={columnOneImage}
        heading={columnOneHeading}
        paragraph={columnOneParagraph}
        buttonLink={columnOneButtonLink}
        buttonLabel={columnOneButtonLabel}
      />
      <Column
        image={columnTwoImage}
        heading={columnTwoHeading}
        paragraph={columnTwoParagraph}
        buttonLink={columnTwoButtonLink}
        buttonLabel={columnTwoButtonLabel}
      />
      <Column
        image={columnThreeImage}
        heading={columnThreeHeading}
        paragraph={columnThreeParagraph}
        buttonLink={columnThreeButtonLink}
        buttonLabel={columnThreeButtonLabel}
      />
    </div>
    {bottomButtonLink && bottomButtonLabel && (
      <div css={bottomButtonWrapper}>
        <ButtonLink
          size={bottomButtonSize}
          to={bottomButtonLink.url}
          type={bottomButtonStyle}
        >
          {bottomButtonLabel}
        </ButtonLink>
      </div>
    )}
  </div>
);
